/*
###############################################################################
##
## @file:         ie.scss
## @description:  Defines IE specific styling for IE9 and lower
##
##                This file is compiled into ie.css
##
###############################################################################
*/
/* ---------- Import base ---------- */
/*
###############################################################################
##
## @file:         _init.scss
## @description:  Get all variables
##
###############################################################################
*/
/* ---------- Import vars & basics ---------- */
/* ==========================================================================
   HTML5 Boilerplate styles - h5bp.com (generated via initializr.com)
   ========================================================================== */
html,
button,
input,
select,
textarea {
  color: #222;
}

body {
  font-size: 1em;
  line-height: 1.4;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
   Print styles
   ========================================================================== */
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster: h5bp.com/s */
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  /*
     * Don't show links for images, or javascript/internal links
     */
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
    /* h5bp.com/t */
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}

/*
###############################################################################
##
## @file:         _variables.scss
## @description:  Defines some global variables for the website
##
###############################################################################
*/
/**
 * Variables
 * ------------------------------------------------------------------------- */
/**
 * Breakpoints
 * ------------------------------------------------------------------------- */
/**
 * Icons
 * ------------------------------------------------------------------------- */
/*
###############################################################################
##
## @file:         inc.colors.less
## @description:  Defines the colors for the website
##
###############################################################################
*/
/**
 *  default color definitions - only change values, do not rename!
 * ------------------------------------------------------------------------- */
/* used for borders, hr, fields, etc */
/* selection colors */
/* status & validation */
/**
 * custom color definitions
 * ------------------------------------------------------------------------- */
/*
###############################################################################
##
## @file:         inc.fonts.less
## @description:  Defines the fonts for the website
##
###############################################################################
*/
/* @font-face {
    font-family: 'medicons';
    src:url('../fonts/icomoon.eot?s2q859');
    src:url('../fonts/icomoon.eot?#iefixs2q859') format('embedded-opentype'),
        url('../fonts/icomoon.woff?s2q859') format('woff'),
        url('../fonts/icomoon.ttf?s2q859') format('truetype'),
        url('../fonts/icomoon.svg?s2q859#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
} */
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon/fonts/icomoon.eot?pm39b1");
  src: url("../fonts/icomoon/fonts/icomoon.eot?pm39b1#iefix") format("embedded-opentype"), url("../fonts/icomoon/fonts/icomoon.woff2?pm39b1") format("woff2"), url("../fonts/icomoon/fonts/icomoon.ttf?pm39b1") format("truetype"), url("../fonts/icomoon/fonts/icomoon.woff?pm39b1") format("woff"), url("../fonts/icomoon/fonts/icomoon.svg?pm39b1#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

/*
###############################################################################
##
## @file:         _mixins.scss
## @description:  Defines custom mixins
##
###############################################################################
*/
/**
 * Breakpoint
 *
 * Usage:
 * div { width: 75%;
 *   @include bp($tablet_narrow, max) { width: 50%; }
 * }
 *
 * Result:
 * div { width: 75%; }
 * @media (max-width: 37.438em) {
 *   div { width: 50%; }
 * }
 * ------------------------------------------------------------------------- */
/**
 * REM with px fallback for all properties
 *
 * @author drublic
 * https://github.com/drublic/Sass-Mixins/blob/master/partials/_rem.scss
 *
 * Usage:
 * div { @include x-rem(margin, 10, 20, 30, 40); }
 *
 * Result:
 * div {
 *   margin: 160px 320px 480px 640px;
 *   margin: 10rem 20rem 30rem 40rem;
 * }
 *
 * Note: Depending on the font-size of the root-element `rem` calculates a
 * property of a current element. The fallback solution excepts a
 * `default-font-size`-argument which is 16px by default.
 * ------------------------------------------------------------------------- */
/**
 * Input placeholder
 *
 * Usage:
 * input {
 *   @include placeholder {
 *     color: #fff;
 *   }
 * }
 * ------------------------------------------------------------------------- */
/**
 * Min-height / min-width
 *
 * Usage:
 * div { @include x-min-size(height, 100%) }
 * ------------------------------------------------------------------------- */
/**
 * Background transparency with fallback
 *
 * Usage:
 * div { @include x-bg-opacity(#333, 0.5); }
 * ------------------------------------------------------------------------- */
/**
 * Icon replacement
 *
 * Usage:
 * .replace-with-icon { @include x-icon($icon-locked); color: #fff; ... }
 * ------------------------------------------------------------------------- */
/*
###############################################################################
##
## @file:         _helpers.scss
## @description:  Defines the helper classes for the website
##
###############################################################################
*/
/**
 * helper classes
 * ------------------------------------------------------------------------- */
.hidden {
  display: none !important;
  visibility: hidden;
}

.invisible {
  visibility: hidden;
}

.element-invisible,
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.element-invisible.element-focusable:active,
.element-invisible.element-focusable:focus,
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

/* ---------- Import overrides ---------- */
/*
###############################################################################
##
## @file:         ie.scss
## @description:  Defines IE specific styling for IE9 and lower
##
##                This file is compiled into ie.css
##
###############################################################################
*/
/* =========================================================================
 * 1. IE9 & lower
 * ========================================================================= */
/* =========================================================================
 * 2. IE8 & lower
 * ========================================================================= */
/* =========================================================================
 * 2. IE7 & lower
 * ========================================================================= */
.lt-ie8 {
  /* For IE 6/7 (trigger hasLayout) */
}

.lt-ie8 *, .lt-ie8 *:before, .lt-ie8 *:after {
  /* @include x-polyfill-boxsizing; */
}

.lt-ie8 .clearfix {
  zoom: 1;
}
