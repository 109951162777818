@charset "UTF-8";

/*
###############################################################################
##
## @file:         ie.scss
## @description:  Defines IE specific styling for IE9 and lower
##
##                This file is compiled into ie.css
##
###############################################################################
*/

/* ---------- Import base ---------- */
@import "global/init";

/* ---------- Import overrides ---------- */
@import "components/ie-legacy";
