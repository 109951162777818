@charset "UTF-8";

/*
###############################################################################
##
## @file:         ie.scss
## @description:  Defines IE specific styling for IE9 and lower
##
##                This file is compiled into ie.css
##
###############################################################################
*/


/* =========================================================================
 * 1. IE9 & lower
 * ========================================================================= */


/* =========================================================================
 * 2. IE8 & lower
 * ========================================================================= */
.lt-ie9                 {
}



/* =========================================================================
 * 2. IE7 & lower
 * ========================================================================= */
.lt-ie8                 {
  *, *:before, *:after    { /* @include x-polyfill-boxsizing; */ }
  .clearfix               { zoom: 1; } /* For IE 6/7 (trigger hasLayout) */
}
